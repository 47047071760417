<template>
  <div class="empty-container">
    <div class="empty-image">
      <img :src="imageIcon" alt="empty" />
    </div>
    <div class="empty-title">
      {{ title }}
    </div>
    <div class="empty-content">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageIcon: String,
    title: String,
    content: String
  }
};
</script>
<style lang="scss" scoped>
.empty-container {
  border-radius: 8px;
  background-color: $brand-neutral-0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  padding: 64px 128px;
  @include flex-horizontal-center;
  flex-direction: column;
  .empty-title {
    @include subtitle-large($brand-neutral-900);
    letter-spacing: 0.055px;
    margin-top: 24px;
  }
  .empty-content {
    margin-top: 8px;
    @include body-regular($brand-neutral-700);
  }
  @media only screen and (max-width: 991px) {
    padding: 32px 48px;
  }
  @media only screen and (max-width: 767px) {
    padding: 24px;
  }
}
</style>
